
// Register sub-components manually, for Nuxt doesn't register
// them automatically because <IkMisJeCard> is a functional component
import Vue from 'vue'

Vue.component('IkMisJeCardArticle', () => import('./Article.vue'))
Vue.component('IkMisJeCardDefault', () => import('./Default.vue'))
Vue.component('IkMisJeCardEpisode', () => import('./Episode.vue'))
Vue.component('IkMisJeCardProgram', () => import('./Program.vue'))

export default {
  name: 'IkMisJeCard',
  functional: true,
  props: {
    /**
     * The data content of the card
     */
    publication: {
      type: Object,
      default: null,
    },
  },
  render (createElement, { props, data, children }) {
    if (!props.publication) {
      return
    }

    let dynamicTag
    switch (props.publication.modelId) {
      case '9ad0aecf-f7f9-431c-9193-52bc5bc22971':
        dynamicTag = 'ik-mis-je-card-article'
        break
      case 'fa9af868-5af3-49db-8b55-ff1155b2a53b':
      case 'a45ca4c5-4010-4608-8174-94354e618901':
        dynamicTag = 'ik-mis-je-card-program'
        break
      default:
        dynamicTag = 'ik-mis-je-card-default'
        break
    }
    if (props.publication.type === 'BROADCAST') {
      dynamicTag = 'ik-mis-je-card-episode'
    }

    return createElement(
      dynamicTag,
      {
        props,
        ...data,
      },
      children,
    )
  },
}
