
import { metaDataMixin, dateFormatMixin } from '~/mixins'

export default {
  mixins: [
    metaDataMixin,
    dateFormatMixin,
  ],
  async asyncData({ $api, $config, $log, error }) {

    let articlesFeatured = [],
      articlesPopular = [],
      authorsRecommended = [],
      calltoactions = [],
      recentEpisodes = []

    // Get featured & popular articles
    try {
      const [
        { data: { items: articlesFeaturedItems } = {} },
        { data: { items: articlesPopularItems } = {} },
      ] = await Promise.all([
        $api.articles.getAll({
          channel: 'featured',
          domain: $config.preprEnvironmentDomain,
          limit: 1,
        }),
        $api.articles.getAll({
          channel: 'popular',
          domain: $config.preprEnvironmentDomain,
          limit: 4,
        }),
      ])
      console.log(articlesFeaturedItems)
      articlesFeatured = articlesFeaturedItems
      articlesPopular = articlesPopularItems
    } catch (e) {
      error({
        statusCode: 404,
        message: '(404) Er is iets misgegaan waardoor de pagina niet geladen kon worden.',
        context: `Couldn't find page.`,
        severity: 'info',
      })
      return false
    }

    // Get popular authors (Aanbevolen)
    try {
      const { data: { items: authorsRecommendedItems } = {} } = await $api.persons.getAll(
        {
          channel: 'recommended',
          domain: 'generieke-content-eo',
          limit: 4,
          sort: 'publish_on',
          community: $config.preprCommunityId,
        },
      )
      authorsRecommended = authorsRecommendedItems
    } catch (e) {
      $log.warn(error)
    }
    
    // Get call to actions
    try {
      const { data: { items: calltoactionsItems } = {} } = await $api.calltoactions.getAll({
        channel: 'featured',
        domain: $config.preprEnvironmentDomain,
        limit: 3,
      })
      calltoactions = calltoactionsItems
    } catch (e) {
      $log.warn(error)
    }

    // Get recent episoodes
    try {
      const { data: { items: recentEpisodesItems } = {} } = await $api.poms.playables.getAll({
        descendantOf: 'VPWON_1266183',
        limit: 3,
      })
      recentEpisodes = recentEpisodesItems
    } catch (e) {
      $log.warn(error)
    }
      
    return {
      articlesFeatured,
      articlesPopular,
      authorsRecommended,
      calltoactions,
      recentEpisodes,
    }
  },
  data () {
    return {
      activeTabsList: 0,
      articles: [],
      articlesFeatured: [],
      articlesPopular: [],
      authorsRecommended: [],
      hasMore: true,
      hero: 'https://584lyuyf5181.b-cdn.net/548cf3ba-83d3-4d41-a0c8-80162d1b443d',
      recentEpisodes: [],
      calltoactions: [],
    }
  },
  async fetch () {
    const showAtLeast = 13

    let { data: { items: articles = [], meta: { total = 0 } = {} } = {} } = await this.$api.articles.getAll({
      domain: this.$config.preprEnvironmentDomain,
      offset: this.articles.length,
      limit: this.articles.length ? 9 : showAtLeast,
    })



    // mechanism to remove doubles on the first load of articles
    if (this.articlesFeatured.length && !this.articles.length) {
      // remove duplicate
      articles = articles.filter(article => article.id !== this.articlesFeatured[0].id)
      // or remove last article
      if (articles.length >= showAtLeast) {
        articles.pop()
      }
    }

    this.articles.push(...articles)
    this.hasMore = total > this.articles.length
  },
  computed: {
    articleList () {
      return [
        ...this.articlesFeatured,
        ...this.articles,
      ]
    },
    socialCookiesAllowed () {
      return this.$store.state.cookieConsent.social
    },
  },
}
